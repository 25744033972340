export const orderTableColumns = [
  {
    title: '服务站名称',
    dataIndex: 'station_name',
  },
  {
    title: '负责人',
    dataIndex: 'director_name',
    scopedSlots: { customRender: 'director_name' },
  },
  {
    title: '四合院账号',
    dataIndex: 'service_phone',
  },
  {
    title: '联系方式',
    dataIndex: 'director_phone',
  },
  {
    title: '服务区域',
    dataIndex: 'service_region',
    scopedSlots: { customRender: 'service_region' },
  },
  {
    title: '服务类型',
    dataIndex: 'service_item',
    scopedSlots: { customRender: 'service_item' },
  },
  {
    title: '实名认证',
    dataIndex: 'is_real',
    scopedSlots: { customRender: 'is_real' },
  },
  {
    title: '合作方式',
    dataIndex: 'cooperation_type',
    scopedSlots: { customRender: 'cooperation_type' },
  },
  {
    title: '操作',
    width: '320px',
    scopedSlots: { customRender: 'action' },
  },
]
export const rulesList = {
  station_name: [
    {
      required: true,
      message: '请输入服务站名称',
      trigger: 'blur',
    },
  ],
  director_name: [
    {
      required: true,
      message: '请输入负责人名称',
      trigger: 'blur',
    },
  ],
  service_phone: [
    {
      required: true,
      message: '请输入四合院账号',
      trigger: 'blur',
    },
  ],
  director_phone: [
    {
      required: true,
      message: '请输入联系方式',
      trigger: 'blur',
    },
  ],
  service_region: [
    {
      required: true,
      message: '请选择服务区域',
      trigger: 'blur',
    },
  ],
  service_item: [
    {
      required: true,
      message: '请选择服务类型',
      trigger: 'blur',
    },
  ],
  is_real: [
    {
      required: true,
      message: '请选择是否实名认证',
      trigger: 'blur',
    },
  ],
  cooperation_type: [
    {
      required: true,
      message: '请选择合作方式',
      trigger: 'blur',
    },
  ],
}

export const serviceItemList = [
  {
    id: 1,
    service: '测量',
  },
  {
    id: 2,
    service: '设计',
  },
  {
    id: 3,
    service: '拆单',
  },
  {
    id: 4,
    service: '安装',
  },
  {
    id: 5,
    service: '整套服务',
  },
]
